<template>
  <div class="g-box g-role-list-box" v-loading="loading">
    <div class="m-form-inline" data-m="select">
      <div class="m-form-inline-mn">
        <table-filter
          :show-list="showList"
          :hide-list="hideList"
          v-model="formInline"
          @filterChange="toPage(1)"
        ></table-filter>
      </div>
    </div>
    <div class="m-list" data-m="list">
      <m-table-icons
        @sizeChange="(val) => (tableSize = val)"
        :columns="tableTileTemp"
        @columnChange="(val) => (tableTile = val)"
      >
        <div slot="btns">
          <el-button
            class="u-add"
            type="primary"
            @click="addCode"
            v-if="tool.checkButton('code-add')"
          >
            新增字典
          </el-button>
        </div>
      </m-table-icons>
      <el-table
        :data="tableData"
        :size="tableSize"
        stripe
        tooltip-effect="dark"
        ref="multipleTable"
      >
        <el-table-column align="center" label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column
          align="center"
          v-for="item in tableTile"
          :label="item.columnLabel"
          :prop="item.prop"
          :key="item.key"
          :width="item.width"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              circle
              icon="iconfont iconliebiao-chakan"
              title="详情"
              size="mini"
              @click="view(scope.row.id)"
              v-if="tool.checkButton('code-info-list')"
            ></el-button>
            <el-button
              circle
              icon="iconfont iconliebiao-bianji"
              title="编辑"
              size="mini"
              @click="edit(scope.row.id)"
              v-if="tool.checkButton('code-edit')"
            ></el-button>
            <el-button
              circle
              icon="iconfont iconliebiao-shanchu"
              title="删除"
              size="mini"
              @click="remove(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="m-page" data-m="list" v-if="formInline.page.total > 0">
      <p class="u-p">总共{{ formInline.page.total }}条数据</p>
      <el-pagination
        layout="prev, pager, next, sizes, jumper"
        :total="formInline.page.total"
        :page-size.sync="formInline.page.pageSize"
        :current-page="formInline.page.currentPage"
        @current-change="toPage"
        :page-sizes="[10, 20, 30, 40]"
        @size-change="toPage(1)"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from './../../../api'
import tableFilter from '../../../components/table-filter'
import mTableIcons from '../../../components/m-table-icons'
import VueCookie from 'vue-cookie'

export default Vue.extend({
  name: 'code-list',
  components: { tableFilter, mTableIcons },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      tableSize: 'medium',
      tableTileTemp: [],
      showList: [{
        copName: 'el-input',
        placeholder: '字典名称',
        key: 'value',
        name: '',
        attrs: ['placeholder']
      },
      {
        copName: 'el-input',
        placeholder: '字典代码',
        key: 'code',
        name: '',
        attrs: ['placeholder']
      }],
      hideList: [],
      formInline: {
        value: '',
        code: '',
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      filter: {
      },
      tableData: [],
      tableTile: [{
        key: '1',
        columnLabel: '字典名称',
        prop: 'value',
        width: ''
      }, {
        key: '2',
        columnLabel: '字典代码',
        prop: 'code',
        width: ''
      }, {
        key: '3',
        columnLabel: '所属系统',
        prop: 'systemName',
        width: ''
      },
      {
        key: '4',
        columnLabel: '字典说明',
        prop: 'description',
        width: ''
      }],
      loading: true
    }
  },
  computed: {},
  watch: {},
  beforeCreate () {
  },
  created () {
    this.tableTileTemp = JSON.parse(JSON.stringify(this.tableTile))
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {
      this.toPage(1)
    },
    addCode () {
      this.$router.push('/authority-management/code/code-add')
    },
    onSubmit () {
      this.loading = true
      api.auth.postQueryDictList({
        page: this.formInline.page.currentPage,
        size: this.formInline.page.pageSize,
        dict: {
          value: this.formInline.value,
          code: this.formInline.code,
          parkId: parseInt(VueCookie.get('parkId'))
        }
      }).then(res => {
        this.tableData = res.data
        this.formInline.page.total = res.num
        this.loading = false
      })
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    edit (id) {
      this.$router.push('/authority-management/code/code-list/code-edit/' + id)
    },
    view (id) {
      this.$router.push('/authority-management/code/code-info/' + id)
    },
    remove (id) {
      this.$confirm('请确认是否删除此数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.auth.postDeleteDictById({
              id: id
            }).then(() => {
              done()

              this.toPage(1)
            }).finally(() => {
              instance.confirmButtonLoading = false
            })
          } else {
            done()
          }
        }
      }).then(() => {

      })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../../assets/css/global-variable";
.g-role-list-box {
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
}
</style>
